<template>
  <div class="three">
    <div class="okok">
      <el-progress type="circle" :percentage="100" status="success" class="okpto"></el-progress>
      <i class="el-icon-check ok"></i>
      <span class="textspan">兑换码已生成</span>
      <!-- <p class="textp">已为您生成兑换码，您可以立即下载兑换码进行线下印刷，印刷支持兑换码印刷和二维码印刷</p> -->
    </div>
   <!-- <div class="btnt">
       <el-button type="primary" size="small">下载兑换码</el-button>
      <el-button type="primary" size="small" plain @click="goDhm">管理兑换码</el-button>
    </div>
    <div class="jcsm">
      <img src="../../../assets/u21.png" alt />
    </div> -->
    <div>
      <el-button type="primary" size="small" style="margin-top: 12px;" @click="ok">完成</el-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import * as API_coupon from "@/api/coupon";
export default {
  computed: {
    ...mapGetters(["shopInfo"])
  },
  props: {
    yfather: Object
  },
  methods: {
    goDhm() {
      console.log(this.yfather,"this.yfatherthis.yfatherthis.yfather");
      console.log(this.shopInfo,"this.shopInfo------------");
      this.$router.push({
        path: `${
          this.shopInfo.shop_type === 1
            ? "/promotion/exchangeCode"
            : "/application/exchangeCode1"
        }`,
        query: { card_id: this.yfather.card_id }
      });
    },
    ok() {
      console.log(this.yfather.card_type, "father");
      if (this.yfather.card_type == 2) {
        this.$router.push({ path: "/promotion/yhjlist" });
      } else if (this.yfather.card_type == 3) {
        this.$router.push({ path: "/promotion/djjlist" });
      } else {
        this.$router.push({ path: "/application/czklist" });
      }
      // this.$router.push({ path: "/promotion/djjlist" });
    }
  }
};
</script>

<style lang="scss">
.okok {
  padding-top: 20px;
  position: relative;
  font-size: 50px;
  color: #4b79af;
  height: 200px;
}
.okok .ok {
  background-color: #fff;
  position: absolute;
  top: 58px;
  left: 50%;
  margin-left: -16px;
}
.okpto {
  margin-left: 46.5%;
}
.textspan {
  padding-left: 20px;
  font-size: 18px;
  color: #666666;
}
.textp {
  text-align: center;
  font-size: 16px;
  color: #666666;
}
.btnt {
  margin-top: 50px;
  text-align: center;
}
.btnt .is-plain {
  margin-left: 130px;
}
.jcsm {
  margin: 20px;
  padding: 50px;
}
.jcsm img {
  width: 100%;
}
</style>
