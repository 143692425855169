import { render, staticRenderFns } from "./czksecend.vue?vue&type=template&id=2c756ab3&scoped=true"
import script from "./czksecend.vue?vue&type=script&lang=js"
export * from "./czksecend.vue?vue&type=script&lang=js"
import style0 from "./czksecend.vue?vue&type=style&index=0&id=2c756ab3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c756ab3",
  null
  
)

export default component.exports