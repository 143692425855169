<template>
  <div class="czkMeal">
    <el-steps
      class="steps"
      :active="active"
      finish-status="success"
      simple
      style="padding-top: 20px;"
    >
      <el-step title="创建卡券"></el-step>
      <el-step title="设置发放规则"></el-step>
      <!-- <el-step title="完成"></el-step> -->
    </el-steps>

    <czkFirst v-on:fson="fSon" v-if="this.active==1?true:false"></czkFirst>
    <czkSecend v-on:fson="fSon1" v-bind:yfather="obj2" v-if="this.active==2?true:false"></czkSecend>
    <czkThree v-bind:yfather="cardMsgall" v-if="this.active==3?true:false"></czkThree>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as API_coupon from "@/api/coupon";
import czkFirst from "./czkfirst";
import czkSecend from "./czksecend";
import czkThree from "./czkthree";
export default {
  computed: {
    ...mapGetters(["shopInfo"])
  },
  components: {
    czkFirst,
    czkSecend,
    czkThree
  },
  data() {
    return {
      active: 1,
      obj1: {},
      obj2: {
        shop_type: "",
        card_type: 2
      },
      cardMsgall: {
        shop_type: ""
      },
      card_type: "",
      cardmsg: {},
      type: 'new'
    };
  },

  watch: {
    $route: function() {
      if (this.$route.name === "coupon") {
        this.active = 1;
      } else if (this.$route.name === "cashCoupon") {
        this.active = 1;
      }
    }
  },
  created() {
    // if (this.$route.query.id) {
    //   this.getcardDetail();
    // }
  },
  mounted() {
    this.getactive();
    this.getCard_id();
    let q = this.$route.query
    if (Object.keys(q).length !== 0) {
      this.type = 'edit'
    }

    // 记录是商家还是企业
    this.obj2.shop_type = this.shopInfo.shop_type;
    this.cardMsgall.shop_type = this.shopInfo.shop_type;
  },

  methods: {
    getCard_id() {
      if (this.$route.query.card_id) {
        this.obj2.card_id = Number(this.$route.query.card_id);
      } else {
        return;
      }
    },
    getactive() {
      if (this.$route.query.active) {
        this.active = Number(this.$route.query.active);
      } else {
        return;
      }
    },
    fSon1(value) {
      this.active = value.flag;
      this.obj1 = value;
      let newobj = {};

      Object.assign(newobj, this.obj1, this.obj2);
      this.cardMsgall = newobj;
      if (value.card_id) {
        this.cardMsgall.card_id = value.card_id;
      } else {
        this.cardMsgall.card_id = this.cardMsgall.id;
      }

      if (this.$route.query.id) {
        // 修改按钮  传过来一个id值
        this.cardMsgall.id = Number(this.$route.query.id);

        API_coupon.modifyCoupons(this.cardMsgall.id, this.cardMsgall)
          .then(res => {
            console.log(res, "修改成功");
          })
          .catch(() => {
            this.active = value.flag - 1;
          });
      } else {
        if (this.cardMsgall.chang) {
          //  自动生成卡密
					this.cardMsgall.card_value=value.card_value
          API_coupon.createCardKeys(this.cardMsgall)
            .then(res => {
              console.log(res, "自动添加成功");
            })
            .catch(() => {
              this.active = value.flag - 1;
            });
        } else {
          //  手动生成卡密
          API_coupon.importCardKeys(value.cardsKeysDTO)
            .then(res => {
              console.log(res, "生成卡密成功");
            })
            .catch(() => {
              this.active = value.flag - 1;
            });
        }
      }
    },
    fSon(value) {
      this.active = value.flag;
      this.obj2 = value;
      let params = {
        ...value
      };
      API_coupon.cardAdd(params)
        .then(res => {
          this.obj2.id = res.id;
        })
        .catch(() => {
          this.active = value.flag - 1;
        });
    },

    next() {
      if (this.active++ > 2) this.active = 1;
    },
    before() {
      if (this.active-- < 2) this.active = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.czkMeal {
  position: relative;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}
.steps {
  background-color: #fff;
}
.btn3 {
  padding: 30px 0;
  text-align: center;
}
</style>

